'use client';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Loader } from '@/components/Loader/Loader';

function Home() {
  const router = useRouter();

  useEffect(() => {
    router.replace('/storylines');
  }, [router]);

  return <Loader fullScreen />;
}

export default Home;
